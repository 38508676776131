import tagsTypes from "constants/tagsTypes";
import isRTX from "./isRTX";

const sanitizeCategory = (option: any, orgName?: string) => {
  const replaceableTypes = {
    [tagsTypes.YEARS_IN_PROGRAM]: tagsTypes.CLASS_YEARS,
    [tagsTypes.BUSINESSES]: isRTX(orgName) ? tagsTypes.COMPANIES : tagsTypes.DEPARTMENTS,
    [tagsTypes.JOBFUNCTIONS]: tagsTypes.DEPARTMENTS,
    [tagsTypes.PROFESSIONS]: tagsTypes.DEPARTMENTS
  };

  return replaceableTypes[option.category] || option.category;
};

export default sanitizeCategory;
