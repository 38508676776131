/* eslint-disable no-restricted-globals */
const serviceWorker = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then(registration => {
        // eslint-disable-next-line no-param-reassign
        registration.onupdatefound = () => {
          const newWorker = registration.installing;

          newWorker.onstatechange = () => {
            if (newWorker.state === "activated") location.reload();
          };
        };
      })
      .catch(err => err);
  }
};

export default serviceWorker;
