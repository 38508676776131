const getUserName = (profile: any, displayLastName = true) => {
  const hasFirstAndLastName = !!profile.firstName;
  let newName = "";
  if (hasFirstAndLastName) {
    newName = displayLastName ? `${profile.firstName} ${profile.lastName}` : profile.firstName;
  } else {
    newName = displayLastName ? profile.name : profile.name?.split(" ")[0];
  }
  return newName;
};

export default getUserName;
