import { IconName } from "@culturehq/components/dist/components/Icon";

import { FIELDS } from "chq";

const tagsTypes = {
  CONNECTION: "connection",
  ALL_ORGANIZATION: "all company",
  BUSINESSES: "businesses",
  COMPANY: "company",
  COMPANIES: "companies",
  DEPARTMENTS: "departments",
  DEPARTMENT: "department",
  INTERESTS: "interests",
  INTEREST: "interest",
  LOCATIONS: "locations",
  LOCATION: "location",
  SKILLS: "skills",
  SKILL: "skill",
  CLASS_YEARS: "class years",
  YEARS_IN_PROGRAM: "years in program",
  CLASS_YEAR: "class_year",
  CLASS_YEAR_TYPE: "classyear",
  CLASS_YEAR_TYPE_PLURAL: "classyears",
  CLUBS: "clubs",
  CLUB: "club",
  PROFESSION: "profession",
  PROFESSIONS: "professions",
  PROGRAMS: "programs",
  PROGRAM: "program",
  DEGREES: "degrees",
  DEGREE: "degree",
  USERS: "users",
  USER: "user",
  ORG_VALUES: "organization values",
  ORG_VALUE: "organization_value",
  ORG_VALUE_TYPE: "organizationvalue",
  STORY_PROMPTS: "story prompts",
  STORY_TRENDS: "story trends",
  STORIES: "stories",
  TRENDS: "trends",
  JOBFUNCTIONS: "job functions"
};

export const getIcon = (category: string): IconName => {
  switch (category?.toLowerCase()) {
    case tagsTypes.INTERESTS:
    case tagsTypes.INTEREST:
      return "heart-ol";
    case tagsTypes.LOCATIONS:
    case tagsTypes.LOCATION:
      return "loc-ol";
    case tagsTypes.COMPANIES:
    case tagsTypes.COMPANY:
      return "building";
    case tagsTypes.DEPARTMENTS:
    case tagsTypes.DEPARTMENT:
      return "ios-people-outline";
    case tagsTypes.SKILLS:
    case tagsTypes.SKILL:
      return "hammer";
    case tagsTypes.PROGRAMS:
    case tagsTypes.PROGRAM:
      return "ios-star-outline";
    case tagsTypes.DEGREES:
    case tagsTypes.DEGREE:
      return "mortarboard";
    case tagsTypes.CLUBS:
    case tagsTypes.CLUB:
      return "ios-people-outline";
    case tagsTypes.CLASS_YEARS:
    case tagsTypes.CLASS_YEAR:
    case tagsTypes.CLASS_YEAR_TYPE:
    case tagsTypes.CLASS_YEAR_TYPE_PLURAL:
      return "clock";
    case tagsTypes.ORG_VALUES:
    case tagsTypes.ORG_VALUE:
    case tagsTypes.ORG_VALUE_TYPE:
      return "diamond";
    default:
      return "heart-ol";
  }
};

export const getFilledIcon = (category: string) => {
  switch (category?.toLowerCase()) {
    case tagsTypes.INTERESTS:
    case tagsTypes.INTEREST:
      return "heart";
    case tagsTypes.LOCATIONS:
    case tagsTypes.LOCATION:
      return "ios-location";
    case tagsTypes.COMPANIES:
    case tagsTypes.COMPANY:
      return "building-filled";
    case tagsTypes.DEPARTMENTS:
    case tagsTypes.DEPARTMENT:
      return "ios-people";
    case tagsTypes.SKILLS:
    case tagsTypes.SKILL:
      return "hammer-filled";
    case tagsTypes.PROGRAMS:
    case tagsTypes.PROGRAM:
      return "star";
    case tagsTypes.DEGREES:
    case tagsTypes.DEGREE:
      return "mortarboard-filled";
    case tagsTypes.CLUBS:
    case tagsTypes.CLUB:
      return "ios-people";
    case tagsTypes.CLASS_YEARS:
    case tagsTypes.CLASS_YEAR:
    case tagsTypes.CLASS_YEAR_TYPE:
    case tagsTypes.CLASS_YEAR_TYPE_PLURAL:
      return "clock-filled";
    case tagsTypes.ORG_VALUES:
    case tagsTypes.ORG_VALUE:
    case tagsTypes.ORG_VALUE_TYPE:
      return "diamond-filled";
    default:
      return "";
  }
};

export const getCategoryLabel = (category: string, labels: FIELDS) => {
  switch (category.toLowerCase()) {
    case tagsTypes.INTERESTS:
    case tagsTypes.INTEREST:
      return "interests";
    case tagsTypes.LOCATIONS:
    case tagsTypes.LOCATION:
      return "locations";
    case tagsTypes.COMPANIES:
    case tagsTypes.COMPANY:
      return labels.companies.plural.toLowerCase();
    case tagsTypes.DEPARTMENTS:
    case tagsTypes.DEPARTMENT:
      return labels.departments.plural.toLowerCase();
    case tagsTypes.SKILLS:
    case tagsTypes.SKILL:
      return "skills";
    case tagsTypes.ORG_VALUES:
    case tagsTypes.ORG_VALUE:
    case tagsTypes.ORG_VALUE_TYPE:
      return "organization values";
    default:
      break;
  }
  return "";
};

export default tagsTypes;
