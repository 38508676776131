import tagsTypes from "constants/tagsTypes";

const getQueryStringKey = category => {
  switch (category.toLowerCase()) {
    case tagsTypes.INTERESTS:
    case tagsTypes.INTEREST:
      return "i";
    case tagsTypes.LOCATIONS:
    case tagsTypes.LOCATION:
      return "l";
    case tagsTypes.COMPANIES:
    case tagsTypes.COMPANY:
      return "co";
    case tagsTypes.BUSINESSES:
    case tagsTypes.PROFESSIONS:
    case tagsTypes.JOBFUNCTIONS:
    case tagsTypes.DEPARTMENTS:
    case tagsTypes.DEPARTMENT:
      return "d";
    case tagsTypes.SKILLS:
    case tagsTypes.SKILL:
      return "s";
    case tagsTypes.PROGRAMS:
    case tagsTypes.PROGRAM:
      return "p";
    case tagsTypes.DEGREES:
    case tagsTypes.DEGREE:
      return "de";
    case tagsTypes.CLUBS:
    case tagsTypes.CLUB:
      return "cl";
    case tagsTypes.CLASS_YEARS:
    case tagsTypes.CLASS_YEAR:
    case tagsTypes.CLASS_YEAR_TYPE:
    case tagsTypes.CLASS_YEAR_TYPE_PLURAL:
      return "cy";
    case tagsTypes.ORG_VALUES:
    case tagsTypes.ORG_VALUE:
    case tagsTypes.ORG_VALUE_TYPE:
      return "v";
    default:
      return "";
  }
};

export default getQueryStringKey;
