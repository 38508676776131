import { isDeloitte, isMicrosoft, isRTX } from "app/utils";
import tagsTypes from "../../constants/tagsTypes";

const getDepartmentFieldCategory = (organizationName?: string) => {
  if (isDeloitte(organizationName)) return tagsTypes.BUSINESSES;
  if (isMicrosoft(organizationName)) return tagsTypes.PROFESSIONS;
  if (isRTX(organizationName)) return tagsTypes.JOBFUNCTIONS;
  return tagsTypes.DEPARTMENTS;
};

export default getDepartmentFieldCategory;
