function generateThumbnail(videoFile: File, timestamp = 1) {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    video.preload = "metadata";
    video.src = URL.createObjectURL(videoFile);

    const cleanup = () => {
      URL.revokeObjectURL(video.src);
      video.src = "";
    };

    video.onloadedmetadata = () => {
      video.currentTime = timestamp;
    };

    video.onseeked = () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      if (ctx) ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(blob => {
        resolve(blob);
        cleanup();
      }, "image/jpeg", 0.75);
    };

    video.onerror = () => {
      reject("Error loading video file. Please ensure the file is valid.");
      cleanup();
    };
  });
}

export default generateThumbnail;
