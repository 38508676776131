import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(updateLocale);
dayjs.extend(relativeTime);

dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "A moment",
    m: "A minute",
    mm: "%d minutes",
    h: "An hour",
    hh: "%d hours",
    d: "A day",
    dd: "%d days",
    M: "A month",
    MM: "%d months",
    y: "A year",
    yy: "%d years"
  }
});

const getHumanize = date => {
  const now = dayjs();
  const someDate = dayjs(date);

  return someDate.from(now);
};

const format = (date, format) => dayjs(date).format(format);

export default {
  getHumanize,
  format
};
